import firebase from "firebase/app";
import "firebase/auth";

import clientCredentials from "./firebase.config";
import { API_URL } from '../config';
import { isMobile, processResponseError } from "../misc";
import axios from "axios";

if (typeof window !== "undefined" && !firebase.apps.length) {
  firebase.initializeApp(clientCredentials);
}

export const checkRedirResTryLogin = async () => {
  const {user} = await firebase.auth().getRedirectResult()
  if(!user) return;
  const idToken = await user.getIdToken();
  return await sessionLogin(idToken)
}

export const firebaseGoogleLogin = async () => {
//   return new Promise((resolve,reject)=>{
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE);
    const authPromise:Promise<any> = isMobile() ? firebase.auth().signInWithRedirect(new firebase.auth.GoogleAuthProvider()) : firebase.auth().signInWithPopup(new firebase.auth.GoogleAuthProvider())
    const resp:any = await authPromise;
    console.log('auth promise was resolved')
    const idToken:any = await resp.user.getIdToken()
    // .then((idToken:any) => {
    //console.log("IdToken :: ", idToken);
    return await sessionLogin(idToken)/*.then(resolve).catch(reject);*/
    // });

    // .catch((error) => {
    //   console.log("Error from firebase login :: ", error);
    //   reject(error);
    // });
//   })
};

const sessionLogin = async (idToken:any) => {
    try {
        const response = await axios(API_URL + "/api/authenticate/firebase/google",{
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + idToken,
        }});
        console.log("Authentication complete, response: ",response)
        //location.reload(true);
        return response.data
    } catch (error) {
        console.error(error);
        throw new Error(processResponseError(error));
    }
};

export default firebase;
